<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Container"
})
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8 w-full flex justify-center">
    <div class="lg:flex gap-8 w-full max-w-[1200px]">
      <slot/>
    </div>
  </div>
</template>

<style scoped>

</style>