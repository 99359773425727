<script lang="ts">
import {defineComponent} from 'vue'
import Container from "~/components/layout/Container.vue";
import {
  FingerPrintIcon,
  UserCircleIcon,
  ArchiveBoxIcon,
} from '@heroicons/vue/24/outline'
export default defineComponent({
  name: "profile",
  components: {Container, FingerPrintIcon, UserCircleIcon},
  computed: {
    navigation() {
      return [
        { name: 'General', href: '/profile', icon: UserCircleIcon },
        { name: 'Security', href: '/profile/security', icon: FingerPrintIcon },
        { name: 'Past orders', href: '/profile/orders', icon: ArchiveBoxIcon },
      ]
    }
  }
})
</script>

<template>
    <container class="lg:flex flex-grow lg:gap-x-16 mt-8">
      <div>
        <Card>
          <aside class="flex overflow-x-auto lg:block lg:w-64 lg:flex-none w-full flex-grow">
            <nav class="flex-none">
              <ul role="list" class="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink :href="item.href" active-class="bg-gray-50 text-primary-600 dark:bg-zinc-900/50 dark:text-primary-500" class="text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-500 hover:bg-gray-50 dark:hover:bg-zinc-900/50  group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold">
                    <component :is="item.icon" :class="[$route.path === item.href ? 'text-primary-600' : 'text-gray-400 group-hover:text-primary-600', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </nav>
          </aside>
        </Card>
      </div>
      <main class="lg:flex-auto mt-4 lg:mt-0">
        <div class="lg:mx-0">
          <slot/>
        </div>
        </main>
    </container>
</template>

<style scoped>

</style>